"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createActionSchema = void 0;

var Yup = _interopRequireWildcard(require("yup"));

var _core = require("@lingui/core");

/**
 * The Yup schema object that final-form will use to validate the form's state
 */
const defaultActionSchema = () => ({
  summary: Yup.string().required(_core.i18n._({
    id: 'action_form.validation.summary_cannot_be_blank',
    message: `Summary cannot be blank`
  })).max(255, _core.i18n._({
    id: 'action_form.validation.summary_cannot_over_255_characters',
    message: `Summary cannot be longer than 255 characters`
  })),
  description: Yup.string(),
  assignee: Yup.string(),
  assigneeId: Yup.string(),
  siteId: Yup.string(),
  skysparkLink: Yup.string().url(_core.i18n._({
    id: 'action_form.validation.report_url_must_be_a_valid_url',
    message: `Report URL must be a valid URL`
  })),
  elec: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.electricity_savings_greater_or_equal_to_0',
    message: `Electricity savings must be a number greater than or equal to 0`
  })),
  water: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.water_savings_greater_or_equal_to_0',
    message: `Water savings must be a number greater than or equal to 0`
  })),
  gas: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.gas_savings_greater_or_equal_to_0',
    message: `Gas savings must be a number greater than or equal to 0`
  })),
  totalFinancialImpact: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.total_financial_impact_greater_or_equal_to_0',
    message: `Total financial impact must be a number greater than or equal to 0`
  })),
  operationalSavings: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.operational_savings_greater_or_equal_to_0',
    message: `Operational savings must be a number greater than or equal to 0`
  })),
  nabersEnergy: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.nabers_energy_greater_or_equal_to_0',
    message: `NABERS energy must be a number greater than or equal to 0`
  })),
  nabersWater: Yup.number().min(0, _core.i18n._({
    id: 'action_form.validation.nabers_water_greater_or_equal_to_0',
    message: `NABERS water must be a number greater than or equal to 0`
  })),
  rule: Yup.string(),
  priority: Yup.string().required(_core.i18n._({
    id: 'action_form.validation.priority_cannot_be_blank',
    message: `Priority cannot be blank`
  })),
  status: Yup.string().required(_core.i18n._({
    id: 'action_form.validation.status_cannot_be_blank',
    message: `Status cannot be blank`
  })),
  context: Yup.string().required(_core.i18n._({
    id: 'action_form.validation.context_cannot_be_blank',
    message: `Type cannot be blank`
  })),
  responsible: Yup.string().required(_core.i18n._({
    id: 'action_form.validation.responsible_cannot_be_blank',
    message: `Responsible cannot be blank`
  }))
});

const createActionSchema = () => Yup.object().shape({ ...defaultActionSchema()
});

exports.createActionSchema = createActionSchema;