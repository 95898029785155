"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messages = void 0;

/*eslint-disable*/
const messages = {
  "action_detail.add_report_link": "ajouter lien de rapport",
  "action_detail.cost_savings": "économies financières",
  "action_detail.description_label": "Description",
  "action_detail.estimated_savings_accordion_label": "estimation d'économies",
  "action_detail.estimated_savings_electricity": "électricité",
  "action_detail.estimated_savings_energy": "Utilité",
  "action_detail.estimated_savings_gas": "gaz",
  "action_detail.estimated_savings_operational": "opérationel",
  "action_detail.estimated_savings_thermal": "thermique",
  "action_detail.estimated_savings_water": "eau",
  "action_detail.inline_edit_prompt": "Cliquer pour modifier",
  "action_detail.insights_and_equipment_accordion_label": "aperçus et équipements",
  "action_detail.links_accordion_label": "liens",
  "action_detail.nabers_energy": "NABERS Énergie",
  "action_detail.nabers_impact": "Impact de NABERS",
  "action_detail.nabers_water": "NABERS Eau",
  "action_detail.report_link_label": "lien de rapport",
  "action_detail.team_and_assignee_accordion_label": "équipe et cessionnaire",
  "action_detail.utility_savings": "économies de services publics",
  "action_detail.v2.description_placeholder": "Ajouter une description",
  "action_details.statuses.severity": "sévérité",
  "action_details.statuses.status": "état",
  "action_details.statuses.type": "type",
  "action_form.add_action": "Ajouter une action",
  "action_form.assignee_label": "Personne responsable",
  "action_form.cancel": "Annuler",
  "action_form.confirm_remove_attachment": "Vous êtes sur le point de supprimer la pièce jointe de cette action, voulez-vous continuer?",
  "action_form.context_label": "Type",
  "action_form.description_label": "Description",
  "action_form.description_placeholder": "description détaillée du problème",
  "action_form.electricity_field_help": "estimation de perte annuelle",
  "action_form.electricity_label": "Économies d'énergie",
  "action_form.energy_savings_field_help": "Impact annuel attendu sur les performances si le problème n'est pas résolu",
  "action_form.energy_savings_label": "économies sur les services publics",
  "action_form.estimated_savings_heading": "économies estimées",
  "action_form.gas_field_help": "estimation de perte annuelle",
  "action_form.gas_label": "Gaz (GJ)",
  "action_form.links_heading": "liens",
  "action_form.nabers_energy_label": "NABERS énergie (star)",
  "action_form.nabers_heading": "Impact de NABERS",
  "action_form.nabers_water_label": "NABERS eau (star)",
  "action_form.operational_savings_field_help": "Impact annuel attendu de la non-utilité si le problème n'est pas résolu",
  "action_form.operational_savings_label": "Économies opérationnelles",
  "action_form.priority_label": "sévérité",
  "action_form.remove": "Supprimer",
  "action_form.report_url_label": "URL du Rapport",
  "action_form.savings_heading": "Titre d'économies",
  "action_form.select_placeholder": "sélectionner...",
  "action_form.site": "Site",
  "action_form.status_label": "Statut de l'action",
  "action_form.summary_label": "Résumé",
  "action_form.team_assignee_heading": "équipe et cessionnaire",
  "action_form.team_label": "équipe",
  "action_form.thermal_field_help": "estimation de perte annuelle",
  "action_form.thermal_label": "Thermique (kWh)",
  "action_form.utility_savings_heading": "économie de services publics",
  "action_form.validation.context_cannot_be_blank": "Le type ne peut pas être vide",
  "action_form.validation.electricity_savings_greater_or_equal_to_0": "Les économies d'électricité doivent être un nombre supérieur ou égal à 0",
  "action_form.validation.gas_savings_greater_or_equal_to_0": "Les économies de gaz doivent être un nombre supérieur ou égal à 0",
  "action_form.validation.nabers_energy_greater_or_equal_to_0": "L'énergie NABERS doit être un nombre supérieur ou égal à 0",
  "action_form.validation.nabers_water_greater_or_equal_to_0": "L'eau NABERS doit être un nombre supérieur ou égal à 0",
  "action_form.validation.operational_savings_greater_or_equal_to_0": "Les économies opérationnelles doivent être un nombre supérieur ou égal à 0",
  "action_form.validation.priority_cannot_be_blank": "\"Priorité\" ne peux pas être un champ vide",
  "action_form.validation.report_url_must_be_a_valid_url": "L'URL du rapport doit être un URL valide",
  "action_form.validation.responsible_cannot_be_blank": "\"Responsable\" ne peut pas être un champ vide",
  "action_form.validation.status_cannot_be_blank": "\"Statut\" ne peut pas être un champ vide",
  "action_form.validation.summary_cannot_be_blank": "\"Résumé\" ne peut pas être un champ vide",
  "action_form.validation.summary_cannot_over_255_characters": "résumé ne peut dépasser 255 caractères",
  "action_form.validation.total_financial_impact_greater_or_equal_to_0": "L'impact financier total doit être un nombre supérieur ou égal à 0",
  "action_form.validation.water_savings_greater_or_equal_to_0": "Les économies d'eau doivent être un nombre supérieur ou égal à 0",
  "action_form.water_field_help": "Estimation de pertes annuelles",
  "action_form.water_label": "Eau (kL)",
  "actions.add_action": "Ajouter une action",
  "actions.closed_actions": "Actions clôturées",
  "actions.export": "Exporter",
  "actions.filters": "Filtres",
  "actions.monitoring_actions": "Actions Suivi",
  "actions.open_actions": "Actions ouvertes",
  "actions.sites": "Sites",
  "actions.v2.include_open_status": "<0>Montrer <1>Actions</1> Ouvertes</0>",
  "actions.v2.status_select.applied": "Appliqué",
  "actions.v2.status_select.filter_status": "État du filtre",
  "add_message.add_comment_button": "ajouter commentaire",
  "add_message.attach_file_button": "Joindre fichier",
  "add_message.message_placeholder": "Ajouter votre commentaire",
  "analysis.analyses": [["analysisCount", "plural", {
    one: "Analysis",
    other: "Analyses"
  }]],
  "analysis.equips": [["equipCount", "plural", {
    one: "Equip",
    other: "Equips"
  }]],
  "analysis.sites": [["siteCount", "plural", {
    one: "Site",
    other: "Sites"
  }]],
  "app_chrome.actions": "Actions",
  "app_chrome.deployments": "Déploiements",
  "app_chrome.explorer": "Explorateur",
  "app_chrome.histories": "Histoires",
  "app_chrome.insights": "Aperçus",
  "app_chrome.labs": "Laboratoires",
  "app_chrome.metering": "Comptage",
  "app_chrome.monitoring": "Suivi",
  "app_chrome.reports": "Rapports",
  "attachment_modal.add": "Ajouter",
  "attachment_modal.add_attachment": "Ajouter une pièce jointe",
  "change_password.confirm_new_password_placeholder": "Nouveau mot de passe (réintroduire) ",
  "change_password.current_password": "Mot de passe actuel",
  "change_password.new_password": "Nouveau mot de passe",
  "change_password_modal_data.change_user_password": "Modifier le mot de passe de l'utilisateur",
  "change_password_modal_data.confirm_new_password_error_message": "Confirmation du Nouveau Mot de Passe requis.",
  "change_password_modal_data.current_password_error_message": "Mot de passe actuel est requis.",
  "change_password_modal_data.new_password_error_message": "Nouveau mot de passe actuel est requis.",
  "change_password_modal_data.password_mismatch": "Le Nouveau Mot de Passe et la confirmation du Nouveau Mot de Passe ne correspondent pas. Re-essayez.",
  "change_password_modal_data.update_password": "Mettre à jour le mot de passe",
  "columns.actions_count": "Actions",
  "columns.affected_equips": "Équipements",
  "columns.equip_details": "Détails",
  "columns.equip_name": "Équipement",
  "columns.est_savings": "Économies estimées",
  "columns.id": "Identifiant",
  "columns.latest": "Plus récent",
  "columns.priority": "Priorité",
  "columns.region": "Région",
  "columns.rule_name": "Règle",
  "columns.rule_type": "Type de Règle",
  "columns.severity": "Sévérité",
  "columns.site": "Site",
  "columns.status": "Statut",
  "columns.summary": "Résumé",
  "columns.team": "équipe",
  "columns.triggered_rules": "Résultats",
  "columns.type": "Type",
  "columns.updated": "Mis à jour",
  "context_list.data_uptime_label": "Disponibilité des données",
  "context_list.fdd_label": "FDD",
  "context_list.metering_label": "Mesure",
  "context_list.optimisation_label": "étiquette d'optimisation",
  "context_list.other_label": "Autre",
  "context_list.warranty_label": "Garantie",
  "equip_preview.confirm_unassociate": ["Vous êtes sur le point de dissocier ", ["equipName"], " de cette action, continuer?"],
  "equip_preview.description_label": "Description:",
  "equip_preview.duration_label": "Durée:",
  "equip_preview.durations": "Durées",
  "equip_preview.equip_label": "Équipement:",
  "equip_preview.hit_details": "Renseignements sur le résultat",
  "equip_preview.latest": "Dernière",
  "equip_preview.latest_hit_label": "Dernier résultat:",
  "equip_preview.rule_label": "Règle:",
  "equip_preview.severity": "Gravité",
  "equip_preview.severity_label": "Sévérité:",
  "equip_preview.unassociate": "dissocier",
  "equip_preview.view_trend_graph": "Voir graphique de tendance",
  "estimated_savings.electricity": "Électricité",
  "estimated_savings.gas": "Gaz",
  "estimated_savings.operational": "Opérationnel",
  "estimated_savings.thermal": "Thermique",
  "estimated_savings.utility": "Utilitaire",
  "estimated_savings.water": "Eau",
  "forgot_password.form_title": "Mot de Passe oublié",
  "forgot_password.instructions": "Entrer le nom d’utilisateur ou l’adresse courriel du compte. Nous vous enverrons un lien de réinitialisation par courriel.",
  "forgot_password.submit": "Envoyer",
  "forgot_password.username_placeholder": "Entrer votre nom d’utilisateur...",
  "inline_edit.add_assignee": "ajouter un cessionnaire",
  "inline_edit.assignee_label": "cessionnaire",
  "inline_edit.team_label": "équipe",
  "inline_edit.team_select_placeholder": "sélectionner",
  "inline_edit.v2.remove_assignee": "Supprimer",
  "inline_edit_action.attach": "joindre fichier",
  "inline_edit_action.attach_new": "joindre nouveau",
  "inline_edit_action.cancel_attachment": "annuler",
  "inline_edit_action.invalid_filetype": "Pour des raisons de sécurité, ce type de fichier n’est pas autorisé. Veuillez sélectionner un autre fichier.",
  "inline_edit_action.screenshot": "capture d'écran",
  "inline_edit_action.trend_graph_edit": "Graphique de tendance",
  "inline_edit_action.upload_attachment": "télécharger",
  "inline_edit_action.v2.edit_url": "modifier",
  "inline_edit_action.v2.trend_graph_anchor": "voir rapport",
  "issue_pane.add_comment": "Ajouter un Commentaire",
  "issue_pane.attach_file": "Ajouter une pièce jointe",
  "issue_pane.close": "Clôturer",
  "language_select.language": "Langue",
  "login.forgot_password": "Mot de passe oublié?",
  "login.password": "Mot de passe",
  "login.sign_in": "Se connecter",
  "login.support": "Aide",
  "login.username": "Nom d'utilisateur",
  "mention_box.add_comment": "Ajouter un Commentaire",
  "modal.close": "Clôturer",
  "monitoring.all": "tout",
  "monitoring.comfort": "confort",
  "monitoring.connectivity": "connectivité",
  "monitoring.data_uptime": "Disponibilité des données",
  "monitoring.fdd": "FDD",
  "monitoring.metering": "comptage",
  "monitoring.optimisation": "Optimisation",
  "monitoring.other": "autre",
  "monitoring.performance": "performance",
  "no_results_overlay.found_for_selected_insight": "0 actions actives trouvées pour le site sélectioné",
  "no_results_overlay.found_for_selected_sites": "0 résultats pour vos sites sélectionnés\n",
  "no_results_overlay.no_insights": "0 résultat correspond au(x) filtre(s) actuel(s). Vous pouvez voir cette erreur parce qu'il n'existe aucune analyse dans la plage horaire actuelle ou parce que vous essayez d'accéder aux analyses d'une règle dans une organisation où la règle n'est pas activée.",
  "no_results_overlay.no_results_message": "correspond(ent) au(x) filtre(s)",
  "not_found.page_not_available": "Page indisponible. La page demandée n’existe plus ou ne vous est pas accessible.",
  "not_found.related_links": "Liens connexes",
  "priority_list.blocker": "Obstacle",
  "priority_list.critical": "Critique",
  "priority_list.major": "Majeure",
  "priority_list.minor": "Mineure",
  "priority_list.moderate": "Modérée",
  "priority_list.trivial": "Anodine",
  "profile.account": "Compte",
  "profile.change_password": "Modifier le mot de passe",
  "profile.display_name": "Nom d'Affichage",
  "profile.email": "Courriel",
  "profile.email_notifications": "Notifications par e-mail",
  "profile.groups": "Groupes",
  "profile.language": "Langue",
  "profile.notifications.action_transitioned": "Transition vers l’action",
  "profile.notifications.action_transitioned.description": "Recevez une notification lorsqu’une personne modifie le statut d’une action que vous avez créée ou à laquelle vous êtes affecté.",
  "profile.notifications.assigned_to_action": "Affecté à l’action",
  "profile.notifications.assigned_to_action.description": "Recevez une notification lorsque quelqu’un vous affecte à une action.",
  "profile.notifications.comment_added": "Commentaire ajouté",
  "profile.notifications.comment_added.description": "Recevez une notification lorsque quelqu’un ajoute un commentaire à une action que vous avez créée ou à laquelle vous êtes affecté.",
  "profile.notifications.critical_action_created": "Action critique créée",
  "profile.notifications.critical_action_created.description": "Recevez une notification lorsqu’une nouvelle action critique est créée.",
  "profile.notifications.disable_all_notifications": "Désactiver toutes les notifications",
  "profile.notifications.mentions": "Mentions",
  "profile.notifications.mentions.description": "Recevez une notification lorsque quelqu’un vous mentionne dans un commentaire.",
  "profile.password": "Mot de passe",
  "profile.profile_management": "Gestion du Profile",
  "profile.projects": "Projets",
  "profile.role": "Rôle",
  "profile.sites": "Sites",
  "profile.success_message": "Le mot de passe de l'utilisateur a été mis à jour avec succès",
  "profile.title": "Administration",
  "profile.username": "Nom d'utilisateur",
  "reports.electricity_usage": "Electricity usage",
  "reports.gas_usage": "Gas usage",
  "reports.generator": "Generator",
  "reports.monthly_summary": "sommaire mensuel",
  "reports.performance": "performance",
  "reports.performance_comfort": "performance de confort",
  "reports.performance_utilities": "performance de services publics",
  "reports.performance_workflow": "performance de flux de travaux",
  "reports.water_usage": "utilisation d'eau",
  "reset_password.confirm_new_password_label": "Confirmer le nouveau mot de passe",
  "reset_password.form_title": "Réinitialiser votre mot de passe",
  "reset_password.new_password_label": "Nouveau mot de passe",
  "reset_password.password_required": "Le champ ne peut pas être vide",
  "reset_password.password_reset_success": "Réinitialisation réussie, veuillez vous connecter",
  "reset_password.passwords_dont_match": "Les mots de passe ne correspondent pas",
  "reset_password.reset_your_password": "Réinitialiser votre mot de passe",
  "reset_password.set_new_password": "Entrer votre nouveau mot de passe",
  "responsible_list.asset_team_building": "Asset Team - Building",
  "responsible_list.asset_team_electrical": "Asset Team - Electrical",
  "responsible_list.asset_team_plumbing": "Asset Team - Plumbing",
  "responsible_list.asset_team_refrigeration": "Asset Team - Refrigeration",
  "responsible_list.bms_contractor": "Entrepreneur du GTB",
  "responsible_list.bonfire_engineer": "Ingénieur Consultant",
  "responsible_list.bueno": "Bueno",
  "responsible_list.consultant_engineer": "Ingénieur Consultant",
  "responsible_list.emc_energy_optimisation_specialist": "EMC - Energy Optimisation Specialist",
  "responsible_list.emc_operations_it": "EMC - Operations/IT",
  "responsible_list.external_contractors_back_to_back": "External Contractors (Back to Back)",
  "responsible_list.external_contractors_others": "External Contractors (Others)",
  "responsible_list.fmt_hvac": "Field Maintenance Technician (FMT) - HVAC",
  "responsible_list.fmt_refrigeration": "Field Maintenance Technician (FMT) - Refrigeration",
  "responsible_list.mech_contractor": "Entrepreneur en Mécanique",
  "responsible_list.monitoring": "Suivi",
  "responsible_list.other": "Other",
  "responsible_list.other_contractor": "Autre Entrepreneur",
  "responsible_list.service_team": "Service Team (STL)",
  "responsible_list.site_local": "Site Local",
  "responsible_list.site_national": "Site National",
  "responsible_list.state_admin_manager": "State Admin Manager (SAM)",
  "responsible_list.technical_support_team": "Technical Support Team (TST)",
  "responsible_list.wfm_support_centre": "WFM Support Centre",
  "show_more_actions_footer_row.v2.showing_count": ["Affichage de ", ["0"], " sur ", ["1"]],
  "show_more_actions_footer_row.v3.show_all": "afficher tout",
  "show_more_actions_footer_row.v3.show_more": "afficher plus",
  "show_more_grouped_rows.show_all": "afficher tout",
  "show_more_grouped_rows.show_more": "afficher plus",
  "show_more_grouped_rows.showing_count": ["Affichage de ", ["currentCount"], " sur ", ["totalCount"]],
  "show_more_row.hits_selected": ["Résultats (", ["selectedCount"], " sélectionnées)"],
  "show_more_row.show_all": "Afficher tout ",
  "show_more_row.show_more": "Afficher plus",
  "show_more_row.showing_count": ["Page <0>", ["visibleCount"], "</0> de <1>", ["filteredCount"], "</1>"],
  "single_action.back_link": "lien de retour",
  "site_menu.cancel": "Annuler",
  "site_menu.clear_all": "Effacer tout",
  "site_menu.filter_sites": "Filtrer les Sites",
  "site_menu.label": ["Visualisation ", ["0"], " Sites"],
  "site_menu.projects": "Projets",
  "site_menu.select_site_prompt": "Au moins un site doit être sélectionné",
  "site_menu.selected": ["Sélection (", ["0"], ")"],
  "site_menu.sites": ["Sites (", ["0"], ")"],
  "site_menu.update": "Mettre à jour",
  "sort_select.v2.title": "Trier par",
  "status_list.closed": "Clôturé",
  "status_list.in_review": "Examen en cours",
  "status_list.new_action": "nouvelle action",
  "status_list.open": "Ouvert",
  "status_list.parked": "Suspendu",
  "status_list.pending_po": "Bon de commande en attente",
  "status_list.rejected": "Rejected",
  "status_list.scope_required": "Portée des travaux requis",
  "status_list.to_be_quoted": "Devis à effectuer",
  "status_list.to_be_raised_mm": "Nouvelle Action",
  "status_list.triage": "Triage",
  "status_list.work_in_progress": "En cours",
  "ticket_search.v2.placeholder": "Chercher Actions par exemple ‘AHU’ ou ‘critique’",
  "time.last_month": "mois dernier",
  "time.last_six_months": "six derniers mois",
  "time.last_three_month": "trois derniers mois",
  "time.last_three_years": "trois dernières années",
  "time.last_twelve_months": "douze derniers mois",
  "time.last_two_years": "dernier deux ans",
  "time.previous_period": "période précédente",
  "time.same_period_previous_year": "même période année précédente",
  "time.year_to_date": "cumul annuel",
  "transition_reason_list.cannot_fix": "Résolution impossible (problème connu)",
  "transition_reason_list.duplicate": "Double",
  "transition_reason_list.false_alert": "Fausse alarme",
  "transition_reason_list.fixed": "Résolu",
  "transition_reason_list.low_priority": "Faible priorité",
  "transition_reason_list.other": "Autre",
  "transition_reason_list.too_difficult_expensive": "Trop difficile/coûteux",
  "user_menu.group_settings": "Réglages des groupes",
  "user_menu.help": "Aide",
  "user_menu.insight_subscriptions": "Abonnements Insight",
  "user_menu.language": "Langue",
  "user_menu.login": "Connexion",
  "user_menu.logout": "Se déconnecter",
  "user_menu.my_profile": "Profil",
  "user_menu.project_settings": "paramètres de projet",
  "user_menu.salutation": ["Bonjour, ", ["0"]],
  "user_menu.support": "Soutien technique",
  "user_menu.user_settings": "Réglages d'utilisateur",
  "view_action_activity.column_title": "Activité",
  "view_action_activity.no_activity_message": "<0> Ajouter un commentaire ou joindre un fichier au flux d’activité </0> <1> Astuce: envoyez une notification aux autres utilisateurs en ajoutant @ devant leurs noms dans un commentaire </1>",
  "view_action_activity.remove": "Supprimer",
  "view_action_activity.remove_comment_confirmation_text": "Êtes-vous sûr de vouloir supprimer ce commentaire?",
  "view_action_modal.header_title": ["Action ", ["actionLabel"]],
  "{displayHits, plural, one {{displayHits} Hit} other {{displayHits} Hits}}": [["displayHits", "plural", {
    one: [["displayHits"], " Hit"],
    other: [["displayHits"], " Hits"]
  }]],
  "{displayTargetCount, plural, one {{displayTargetCount} Equip} other {{displayTargetCount} Equips}}": [["displayTargetCount", "plural", {
    one: [["displayTargetCount"], " Equip"],
    other: [["displayTargetCount"], " Equips"]
  }]],
  "{equipsCount, plural, one {{unassociatedEquipsCount} of {equipsCount} Equip Unassociated} other {{unassociatedEquipsCount} of {equipsCount} Equips Unassociated}}": [["equipsCount", "plural", {
    one: [["unassociatedEquipsCount"], " of ", ["equipsCount"], " Equip Unassociated"],
    other: [["unassociatedEquipsCount"], " of ", ["equipsCount"], " Equips Unassociated"]
  }]],
  "{numActions, plural, one {# Closed Action} other {# Closed Actions}}": [["numActions", "plural", {
    one: ["#", " Action fermée"],
    other: ["#", " Actions fermées"]
  }]],
  "{numActions, plural, one {# Open Action} other {# Open Actions}}": [["numActions", "plural", {
    one: ["#", " Action ouverte"],
    other: ["#", " Actions ouvertes"]
  }]],
  "{numSelectedSites, plural, one {# Site} other {# Sites}}": [["numSelectedSites", "plural", {
    one: ["#", " Site"],
    other: ["#", " Sites"]
  }]]
};
exports.messages = messages;